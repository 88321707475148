<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">직원 수정</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>권한<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Radio
              :dataList="'member_role' | getSelectDataList"
              :value.sync="viewModel.model.role"/>
          </td>
        </tr>
        <tr>
          <th>로그인상태</th>
          <td>
            <IconSvg
              :iconName="$options.filters.convertIdToText(viewModel.model.loginStatus, 'member_login_status_icon')" :size="16"
              :iconColor="$options.filters.convertIdToColor(viewModel.model.loginStatus, 'member_login_status_icon')"
              :style="{margin: '10px 4px 0 0'}"/>
            <span :class="['txt_tbl',$options.filters.convertIdToColor(viewModel.model.loginStatus, 'member_login_status', 'tc')]">{{ viewModel.model.loginStatus | convertIdToText('member_login_status') }}</span>
            <div
              class="area_right">
              <Button
                v-if="viewModel.model.loginStatus === 'LOCKED'"
                btnSize="small"
                btnStyle="primary_border"
                text="로그인 잠금해제"
                @onClickBtn="viewModel.onClickUnlock()">
                <IconSvg
                  iconName="login_ok"
                  :size="16"
                  iconColor="#216BD9"/>
              </Button>
              <Button
                v-else
                btnSize="small"
                btnStyle="fourthly_border"
                text="로그인 잠금"
                @onClickBtn="viewModel.onClickLock()">
                <IconSvg
                  iconName="login_lock"
                  :size="14"
                  iconColor="#F04848"/>
              </Button>
            </div>
          </td>
        </tr>
        <tr>
          <th>이미지</th>
          <td>
            <FileUpload
              :dataList.sync="viewModel.model.imageList"
              :canMultiple="false"
              :acceptList="['jpg','jpeg','png']"
              :size="[800,800]"
              sizeType="MIN"/>
          </td>
        </tr>
        <!-- <tr>
          <th>이미지 미리보기</th>
          <td>
            <div class="img_profile">
              <img
                :src="viewModel.model.imageList.length > 0 ? viewModel.model.imageList[0].imgPath : ''"
                class="img_g"
                alt="profile"
                onerror="this.src='/assets/images/profile_default.png'">
            </div>
          </td>
        </tr> -->
        <tr>
          <th>이름<IconSvg iconName="essential" :size="6" iconColor="#F04848"/></th>
          <td>
            <Input
              placeholder="이름을 입력해주세요"
              maxWidth="300px"
              :value.sync="viewModel.model.name"
              :valueType="viewModel.modifyValid.name.valueType"
              :isError.sync="viewModel.modifyValid.name.isValid"
              :errorDesc="viewModel.modifyValid.name.validAlertDesc"/>
          </td>
        </tr>
        <tr>
          <th>이메일</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.email }}</span>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="ADMIN_MEMBER_DETAIL"
          :routeParams="{ id : viewModel.model.id }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="저장"
          :disabled="isVaild"
          @onClickBtn="viewModel.onClickComplete()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import FileUpload from '@lemontree-ai/lemontree-admin-common-front/components/common/file/FileUpload';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import MemberDetailViewModel from '@/views/admin/member/viewModel/MemberDetailViewModel'

export default {
  name:'MemberModify',
  components:{
    PageWithLayout,
    TableView,
    IconSvg,
    Input,
    Radio,
    FileUpload,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
    this.viewModel.isModify = this.$route.meta.isModify;
  },
  data(){
    return{
      viewModel: new MemberDetailViewModel(),
    }
  },
  computed:{
    isVaild(){
      return this.viewModel.modifyValidatedResult()
    }
  }
}
</script>
<style scoped>
.img_profile{overflow:hidden;width:150px;border-radius:6px}
</style>